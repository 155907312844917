import "../scss/_help.scss";
import Nav from "../components/Nav";

function Help() {
  return (
    <div className="help-page">
      <h1>Help & FAQ</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, fugiat
        et unde placeat voluptas esse ipsum, quo deleniti repellendus soluta
        reprehenderit? Ab ducimus excepturi qui reprehenderit itaque
        repudiandae, minus nostrum.
      </p>
      <Nav/>
    </div>
  );
}

export default Help;
